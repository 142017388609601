// misc
$body-bg: #FCFCFC;
// $font-size-base: 0.95rem;

// buttons
$input-btn-padding-y    :      .25rem;
$input-btn-padding-x    :      .5rem;

$input-btn-padding-y-sm :      .10rem;
$input-btn-padding-x-sm :      .4rem;

$input-btn-padding-y-lg :      .375rem;
$input-btn-padding-x-lg :      .75rem;

@import "../node_modules/bootstrap/scss/bootstrap";
